/** @jsx jsx */
import { jsx} from "theme-ui"
import { graphql } from "gatsby"
import Product from "../../components/Product/Product"


const ProductPage = (props) => {
	console.log('initializing Product Page Creation','TITLE OF PRODUCT:','[', props.pageContext.product.title ,']');
	console.log("props.pageContext.product.handle, (which is used in the graphql query to find the edges)", props.pageContext.product.handle)
  
	if (!props.data || !props.data.allMarkdownRemark || !props.data.allMarkdownRemark.edges) {
	  console.log('CREATING PRODUCT FAIL (missing props.data)','[', props.pageContext.product.title ,']');
	  return null;
	} else {
	  const edges = props.data.allMarkdownRemark.edges;
	  if (edges.length === 0) {
		console.log('CREATING PRODUCT FAIL (no edges found)','[', props.pageContext.product.title ,']');
		return null;
	  }

	  const cmsData = edges[0].node?.frontmatter;
  
	  if (cmsData) {
		const product = props.pageContext.product;
		return <Product product={product} cmsData={cmsData} />;
	  }
	}
  };
  
  

export const query = graphql`
	query PageQuery($originalName: String){
		allMarkdownRemark(filter: {frontmatter: {title: {eq: $originalName}}}) {
			edges {
			  node {
				frontmatter {
				  templateKey
				  content {
					type
					windowText
					fullText
					fullImage{
					  childImageSharp {
						gatsbyImageData
					  }						
					}
					image {
					  childImageSharp {
						gatsbyImageData
					  }
					}
				  }
				  information
				}
			  }
			}
		}
	}
`


export default ProductPage
