import React, { useState, useContext, useRef } from "react";
import StoreContext from "../../StoreContext/index";
import { triggerCart, addCartItem } from "../Cart/HelperFns";
import * as styles from "./addToCart.module.scss";
import arrow from "../../../static/fonts/Arrow.svg";

const AddToCart = ({ product }) => {
  // State to manage selected variant and amount
  const [variant] = useState(product.variants[0]);
  const [amount, setAmount] = useState(1);
  
  // Store context to track the cart
  const [store, setStore] = useContext(StoreContext);
  const { added } = store;
  
  const initialText = "ADD TO CART";
  const [buttonText, setButtonText] = useState(initialText);

  // Use useRef to store the timeout ID
  const timeoutRef = useRef(null);

  // Handle the click for adding to cart
  const handleClick = () => {
    setButtonText("ADDING...");
    
    // Clear previous timeout if any
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Set a new timeout to revert the button text back to the initial text
    timeoutRef.current = setTimeout(() => {
      setButtonText(initialText);
    }, 700); // 👈️ change text back after 700ms
  };

  // Handle the action to add the product to cart
  const handleAddToCart = () => {
    addCartItem(product, variant, amount, added, setStore);
    triggerCart(setStore);
    handleClick();
  };

  return (
    <>
      {variant && variant.availableForSale ? (
        <div onClick={handleAddToCart} className={`${styles.addToCartBtn} ${styles.available}`}>
          <span className={styles.price}>€{variant.price}</span>
          <div className={styles.parentDiv}>
            <span>{buttonText}</span>
            <img className={styles.arrow} src={arrow} alt="Arrow" />
          </div>
        </div>
      ) : (
        <div className={`${styles.addToCartBtn} ${styles.soldOut}`}>
          <span className={styles.price}>€{variant.price}</span>
          <span>SOLD OUT</span> 
          <img className={styles.arrow} src={arrow} alt="Arrow" />
        </div>
      )}
    </>
  );
};

export default AddToCart;
