// extracted by mini-css-extract-plugin
export var addToCartContainer = "Product-module--addToCartContainer--R6M1d";
export var addToCartContainerMobile = "Product-module--addToCartContainerMobile--+2kIy";
export var cmsInfoText = "Product-module--cmsInfoText---ATJG";
export var doubleImageWrap = "Product-module--doubleImageWrap--S5GAs";
export var fullImage = "Product-module--fullImage---YVjG";
export var fullText = "Product-module--fullText--azHv-";
export var imageWrapper = "Product-module--imageWrapper--pTpfg";
export var informationWrapper = "Product-module--informationWrapper--lG7zL";
export var productCMSImageHorizontal = "Product-module--productCMSImageHorizontal--i71-t";
export var productCMSImageVertical = "Product-module--productCMSImageVertical--xz1kT";
export var productPrice = "Product-module--productPrice--bRTUR";
export var productTitle = "Product-module--productTitle--As23w";
export var singleImageWrap = "Product-module--singleImageWrap--FvuJT";
export var windowText = "Product-module--windowText--1UlDy";
export var wrapper = "Product-module--wrapper--U46aA";